<template>
  <div
    class="smartslider-wrapper"
    v-touch:swipe.right="prevElement"
    v-touch:swipe.left="nextElement">
    <div class="smartslider">
      <div class="smartslider-content">
        <div
          v-if="hasPreviousElement"
          @click.prevent="prevElement"
          class="smartslider-arrow smartslider-arrow--left">
          <icon
            size="xlarge"
            src="/icons/chevron-left.svg">
          </icon>
        </div>
        <div class="smartslider-preview">
          <img
	          :key="previewMedia"
            :src="previewMedia"
            v-if="previewMediaType === 'image'">
          <video
	          playsinline
            controls
            ref="video"
            @ended="jumpToNextElement"
            @play="setInteraction"
            class="smartslider-video"
            :key="previewMedia"
            v-if="previewMediaType === 'video'">
            <source :src="previewMedia" :type="previewMediaOptions?.mimeType">
          </video>
        </div>
        <div
          v-if="hasNextElement"
          @click.prevent="nextElement"
          class="smartslider-arrow smartslider-arrow--right">
          <icon
            size="xlarge"
            src="/icons/chevron-right.svg">
          </icon>
        </div>
      </div>
      <div
        ref="thumbnailsContainer"
        class="smartslider-thumbnail-container">
        <div
          v-if="hasScrollbar"
          @click.prevent="scrollThumbnails('left')"
          class="smartslider-thumbnail-arrow">
          <icon
            size="large"
            src="/icons/chevron-left.svg">
          </icon>
        </div>
        <div
          ref="thumbnails"
          class="smartslider-thumbnails">
          <smart-slider-thumbnail
            :key="idx"
            :category="category"
            :active="elementIndex === idx"
            @select="selectThumbnail(idx)"
            v-for="(category, idx) in elements"
          />
        </div>
        <div
          v-if="hasScrollbar"
          @click.prevent="scrollThumbnails('right')"
          class="smartslider-thumbnail-arrow">
          <icon
            size="large"
            src="/icons/chevron-right.svg">
          </icon>
        </div>
      </div>
    </div>

    <!-- Controls -->
    <div class="smartslider-controls">
      <div
        v-if="showMenuToggle"
        @click.prevent="toggleMenu"
        class="smartslider-control">
        <icon
          size="large"
          :key="menu"
          :src="menu ? '/icons/cross.svg' : '/icons/dots.svg'"
        />
      </div>
    </div>

    <!-- Slide-Out -->
    <div
      v-if="currentElement"
      class="smartslider-menu"
      :class="{ '--closed': menu }"
      :style="{
        color: sliderMenuColor,
        backgroundColor: sliderMenuBackgroundColor,
      }">
      <div
        @click.prevent="toggleMenu"
        class="smartslider-control">
        <icon
          size="large"
          :key="menu"
          :src="menu ? '/icons/cross.svg' : '/icons/dots.svg'"
        />
      </div>
      <div class="smartslider-menuContent">
        <smartSlider-headingMenu :category="currentElement" />
        <smartSlider-locatorMenu
          v-if="isVideoElement && currentElement?.locators?.locators.length > 0"
          class="mt-4"
          @jump="jumpToMilliSecs"
          :category-id="currentElement?.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'category',
    ],
    computed: {
      currentElement() {
        return this.elements.length > this.elementIndex
          ? this.elements[this.elementIndex]
          : null
      },
      hasPreviousElement() {
        return this.loop
          ? true
          : this.elementIndex > 0
      },
      hasNextElement() {
        return this.loop
          ? true
          : this.elementIndex < (this.elements.length - 1)
      },
      previewMedia() {
        if (this.currentElement && this.currentElement.media) {
          const link = (this.currentElement.media?.links || []).find(link => link.rel === 'preview')?.href
	        if (link) {
						return `${link}#t=0.001`
	        }
        }
      },
      previewMediaType () {
        return this.currentElement
          && this.currentElement.media
          ? this.currentElement.media.mediatype
          : null
      },
      previewMediaOptions() {
        switch (this.previewMediaType) {
          case 'video':
            return {
              mimeType: `video/${this.currentElement.media.doctype}`
            }
        }
      },
      sliderMenuColor() {
        return this.currentElement?.attributes?.find(attr => attr.name === 'Color_Secondary')?.value
      },
      sliderMenuBackgroundColor() {
        return this.currentElement?.attributes?.find(attr => attr.name === 'Color_Primary')?.value
      },
      isVideoElement() {
        return this.currentElement?.media?.mediatype === 'video'
      },
      showMenuToggle() {
        return this.currentElement?.attributes?.find(attr => attr.name === 'CMS-Slider')?.value?.includes('~Show Menu~')
      }
    },
    data() {
      return {
        elementIndex: 0,
        elements: [],
        loop: false,
        menu: false,
        hasScrollbar: false,
        loading: false,
	      delay: 2000,
	      timeoutHandler: null,
      }
    },
    async created() {
      this.loading = true
      this.elements = await this.$api.findCategories(this.$helper.extractIdFromSlug(this.category.id))
	    this.elements = this.elements.filter(element => {
		    return element.categorytype === 'media'
	    })
      this.loop = (this.category.attributes || []).find(attr => attr.name === 'CMS-Slider')?.value?.includes('Loop')
	    this.delay = (this.category.attributes || []).find(attr => attr.name === 'CMS-Slider-Delay')?.value || 5000
      this.loading = false
	    this.hasInteraction = false
    },
	  mounted() {
			document.addEventListener('click', this.setInteraction)
	  },
	  beforeUnmount() {
			document.removeEventListener('click', this.setInteraction)
	  },
	  methods: {
			setInteraction() {
				this.hasInteraction = true
			},
      prevElement() {
        if (this.hasPreviousElement) {
          this.elementIndex--

          if (this.loop && this.elementIndex < 0) {
            this.elementIndex = this.elements.length - 1
          }
          this.menu = false
        }
      },
      nextElement() {
        if (this.hasNextElement) {
          this.elementIndex++

          if (this.loop && this.elementIndex > (this.elements.length - 1)) {
            this.elementIndex = 0
          }
          this.menu = false
        }
      },
		  selectThumbnail(idx) {
			  this.elementIndex = idx
			  if (this.timeoutHandler) {
			    clearTimeout(this.timeoutHandler)
			  }
		  },
      toggleMenu() {
        this.menu = !this.menu
      },
      jumpToMilliSecs($event) {
        if (this.$refs.video) {
          this.$refs.video.currentTime = $event / 1000
        }
      },
      scrollThumbnails(dir) {
        const scrollSpeed = dir === 'right'
          ? 100
          : - 100
        if (this.$refs.thumbnails) {
          this.$refs.thumbnails.scrollLeft += scrollSpeed
        }
      },
	    jumpToNextElement() {
				clearTimeout(this.timeoutHandler)
				this.timeoutHandler = setTimeout(() => {
					this.nextElement()

					this.$nextTick(() => {
						if (this.isVideoElement && this.$refs.video && this.hasInteraction) {
							this.$refs.video.play()
						}
					})
				}, this.delay)
	    }
    },
    inject: [
      '$api',
      '$helper',
    ],
    watch: {
      loading() {
        if (!this.loading) {
          this.$nextTick(() => {
            this.hasScrollbar = this.$refs.thumbnails.scrollWidth >= this.$refs.thumbnailsContainer.clientWidth
          })
        }
      },
	    currentElement() {
		    if (this.currentElement
			    && this.currentElement.categorytype === 'media'
			    && this.currentElement.media?.mediatype === 'image') {
					this.jumpToNextElement()
		    }

				/*if (this.currentElement
					&& this.currentElement.media
					&& this.currentElement.media.geometry) {
					let width = document.body.clientWidth
					console.log('width', width, window.innerHeight)
					const mediaSizes = this.currentElement.media.geometry.split('x')
					let scaledHeight = mediaSizes[1] * (width / parseFloat(mediaSizes[0]))
					if (scaledHeight > window.innerHeight) {
						scaledHeight = window.innerHeight
					}
					console.log('Send to parent', scaledHeight + 72)
					window.parent.postMessage({ "FrameHeight": scaledHeight + 72 }, '*')
				}*/
	    }
    }
  }
</script>

<style lang="scss" scoped>
  .smartslider-wrapper {
    @apply
	    pb-4
      w-full
      h-full
      relative
      z-10;

    .smartslider {
      @apply
        flex
        gap-4
        w-full
        h-full
        flex-col
	      relative
        rounded-sm;

      .smartslider-content {
        @apply
          h-full
          w-full
          min-h-0
          relative;

        .smartslider-preview {
          @apply
            flex
            w-full
            h-full
            items-center
            justify-center;

          img {
            @apply
              mx-auto
              max-w-full
              max-h-full
              select-none
              pointer-events-none;
          }

          .smartslider-video {
            @apply
              w-full
	            h-full
	            max-w-full
              max-h-full;
          }
        }
      }

      .smartslider-thumbnail-container {
        @apply
          flex
          gap-2
          w-full
          h-full
          max-h-14
          justify-center
          items-center;

        .smartslider-thumbnails {
          @apply
            flex
            gap-2
            h-full
            select-none
            items-center
            overflow-x-auto;
          scroll-behavior: smooth;

          &::-webkit-scrollbar {
            @apply hidden;
          }
        }

        .smartslider-thumbnail-arrow {
          @apply
            w-8
            flex
            h-full
            max-h-14
            text-custom-primary
            flex-shrink-0
            items-center
            justify-center;
        }
      }
    }

    .smartslider-arrow {
      @apply
        w-8
        flex
        h-16
        z-10
        lg:w-16
        absolute
        text-custom-primary
        items-center
        cursor-pointer
        justify-center
        rounded
        hover:bg-custom-primary
        hover:text-custom-secondary;

      &.smartslider-arrow--left {
        @apply
          top-1/2
          left-0;
        transform: translateY(-50%);
      }

      &.smartslider-arrow--right {
        @apply
          top-1/2
          right-0;
        transform: translateY(-50%);
      }
    }
  }

  .smartslider-controls {
    @apply
	    p-0.5
	    top-0.5
	    right-0.5
      absolute;

    .smartslider-control {
      @apply
        flex
	      p-1
        sm:p-2
        rounded
        text-custom-primary
        items-center
        cursor-pointer
        justify-center
        hover:text-white
        hover:text-custom-primary;
    }
  }

  .smartslider-menu {
    @apply
      p-4
      sm:w-96
	    w-full
      flex
      flex-col
      gap-4
      top-0
      h-full
      right-0
      absolute
      transition
      ease-in-out
      duration-1000;
	  transform: translateX(100%);

    &.--closed {
	    transform: translateX(0%);
    }

    .smartslider-menuContent {
      @apply
        w-full
        h-full
        overflow-y-auto;
    }
  }
</style>