<template>
  <div
    class="locator-container"
    @click.prevent="jump">
    <div class="locator-in">
      <img :src="inPreview" />
    </div>
    <div class="locator-note">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'locator',
      'inPreview',
    ],
    methods: {
      jump() {
        this.$emit('jump', this.locator.msbegin)
      }
    }
  }
</script>

<style lang="scss">
  .locator-container {
    @apply
    flex
    gap-4
    font-normal
    items-center;

    .locator-in,
    .locator-out {
      @apply
        w-48
        h-20;
    }
  }
</style>