<template>
  <div class="smartslider-headingMenu">
    <div class="title">{{ title }}</div>
    <div class="description">{{ description }}</div>
  </div>
</template>

<script>
  export default {
    props: [
      'category',
    ],
    computed: {
      title() {
        return this.category?.attributes?.find(attr => attr.name === 'Heading')?.value
      },
      description() {
        return this.category?.attributes?.find(attr => attr.name === 'Description')?.value
      }
    }
  }
</script>

<style lang="scss" scoped>
  .smartslider-headingMenu {
    .title {
      @apply
	      font-bold
        text-xl;
    }

    .description {
      @apply
        text-base;
    }
  }
</style>