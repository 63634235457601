<template>
  <div class="default-layout">
    <div class="current-category" v-if="breadcrumb || showDownloadButton || showUploadButton">
      <!--      <div class="title" v-if="breadcrumb">{{ category.categoryname }}</div>-->
      <breadcrumbs v-if="breadcrumb && calculatedBreadcrumbs?.length > 0">
        <breadcrumb
            :key="bc.id"
            :index="idx"
            :breadcrumb="bc"
            :category="category"
            :selectable="bc.selectable"
            v-for="(bc, idx) in calculatedBreadcrumbs">
          {{ bc.name }}
        </breadcrumb>
      </breadcrumbs>
      <breadcrumbs v-else>
      </breadcrumbs>
      <div class="controls">
        <div
            v-if="showDownloadButton">
          <c-dropdown
              @select="useDropdown"
              :entries="downloadEntries" class="options">
            <c-button icon="/icons/download.svg">{{ $t('generic.download') }}</c-button>
          </c-dropdown>
        </div>
        <div
            v-if="showEditButtons"
            class="folder-controls">
          <c-dropdown
              :entries="editOptions"
              @select="useEditDropdown"
              v-if="editOptions && editOptions.length > 0"
              class="options"
          >
            <c-button icon="/icons/menu_open.svg">
              {{ $t('generic.menu') }}
            </c-button>
          </c-dropdown>

          <!-- Modals -->
          <sort-category-modal
              v-if="showSortCategoryModal"
              @close="showSortCategoryModal = false">
          </sort-category-modal>

          <new-category-modal
              :category="category"
              v-if="showNewCategoryModal"
              @close="showNewCategoryModal = false">
          </new-category-modal>

          <edit-category-modal
              v-if="showEditCategoryModal"
              :categories="selectedElements"
              @close="showEditCategoryModal = false">
          </edit-category-modal>

          <confirm-modal
              @success="deleteCategory"
              v-if="showDeleteCategoryModal"
              :title="$t('generic.delete')"
              @close="showDeleteCategoryModal = false"
              :successButtonTitle="$t('generic.delete')">
            {{ $t('folders.delete_category_text') }}
          </confirm-modal>

          <template v-if="showUploadButton">
            <input
                multiple
                ref="file"
                type="file"
                class="hidden"
                @change="upload"
            />
            <c-button
                :disabled="loading"
                @click.prevent="enableUpload">
              {{ $t('generic.upload') }}
            </c-button>
          </template>

          <div
              v-if="loading || globalLoading"
              class="upload-loading-indicator">
            <icon
                size="xxlarge"
                src="/icons/loading.svg"
                class="animate-spin text-custom-secondary">
            </icon>
            <div class="text-custom-secondary text-base">{{ loadingMessage }}</div>
          </div>
        </div>
      </div>
    </div>
    <div :class="{
      'component-content': true,
      'component-content--tight': !wide && !isCategorySmartSlider,
      'component-content--with-heading': breadcrumb,
      'component-content--without-breadcrumbs': calculatedBreadcrumbs?.length === 0,
      'component-content--with-breadcrumbs': breadcrumb && calculatedBreadcrumbs?.length > 0,
    }">
      <slot/>
    </div>
  </div>
</template>

<script>
import ContentMixin from '../../../mixin/content'
import {mapGetters} from 'vuex'

export default {
  props: [
    'wide',
    'layout',
    'category',
    'breadcrumb',
  ],
  computed: {
    ...mapGetters({
      hasSelectedElements: 'category/hasSelectedElements',
      selectedElements: 'category/selectedElements',
      copyElements: 'category/copyElements',
      moveElements: 'category/moveElements',
      userAttributes: 'auth/userAttributes',
      valueDomains: 'auth/valueDomains',
      elements: 'category/elements',
      globalLoading: 'app/loading',
    }),
    showEditButtons() {
      let z = this.showUploadButton
          || this.showNewCategoryButton
          || this.showSortCategoryButton
          || this.showEditCategoryButton
          || this.showDeleteCategoryButton
      this.$store.commit('category/showEditButtons', z)
      return z;
    },
	  isCategorySmartSlider() {
			return this.category?.attributes?.find(attr => attr.name === 'CMS-Template')?.value === 'Slider'
	  },
    editOptions() {
      let options = []

      if (this.showSortCategoryButton) {
        options.push({
          sort: 2,
          id: 'sort',
          icon: '/icons/sort.svg',
          label: this.$t('generic.sort')
        })
      }

      if (!this.category?.readOnly) {
        if (this.showNewCategoryButton) {
          options.push({
            sort: 2,
            id: 'newCategory',
            icon: '/icons/plus.svg',
            label: this.$t('generic.new_folder')
          })
        }

        if (this.selectedElements.length !== this.elements.length) {
          options.push({
            sort: 3,
            id: 'select',
            icon: '/icons/select.svg',
            label: this.$t('generic.select_all')
          })
        }
        if (this.hasSelectedElements) {
          options.push({
            sort: 3.1,
            id: 'deselect',
            icon: '/icons/cross.svg',
            label: this.$t('generic.deselect_all')
          })
        }

        if (this.hasSelectedElements || this.showDetailPage) {
          if (this.showEditCategoryButton) {
            if (this.userAttributes
                && this.userAttributes.length > 0) {
              if (this.showEditMenuCategoryButton) {
                options.push({
                  sort: 4,
                  id: 'editCategory',
                  icon: '/icons/edit.svg',
                  label: this.$t('generic.edit')
                })
              }
            }
            if ((!this.copyElements || this.copyElements.length === 0)
                && (!this.moveElements || this.moveElements.length === 0)) {
              if (this.showEditMenuCategoryButton) {
                options.push({
                  sort: 5,
                  id: 'copyCategory',
                  icon: '/icons/copy.svg',
                  label: this.$t('generic.copy')
                })

                options.push({
                  sort: 5,
                  id: 'moveCategory',
                  icon: '/icons/move.svg',
                  label: this.$t('generic.move')
                })
              }
            }
          }
          if (this.showDeleteCategoryButton) {
            options.push({
              sort: 6,
              id: 'deleteCategory',
              icon: '/icons/trash.svg',
              label: this.$t('generic.delete')
            })
          }
        }

        // Paste
        if ((this.copyElements && this.copyElements.length > 0)
            || (this.moveElements && this.moveElements.length > 0)) {
          options.push({
            sort: 5,
            id: 'pasteCategory',
            icon: '/icons/paste.svg',
            label: this.$t('generic.paste')
          })
        }
      }
      return options.sort((o1, o2) => o1.sort > o2.sort ? 1 : -1)
    },
    downloadEntries() {
      let entries = [{id: 'preview', label: this.$t('generic.download_preview'), icon: '/icons/download.svg'}]
      if (this.selectedElements.length === 0) {
        let isAllowedToDownloadOriginal = !!this.category?.media?.links.find(l => l.rel === 'download')
        if (isAllowedToDownloadOriginal) {
          entries.push({id: 'original', label: this.$t('generic.download_original'), icon: '/icons/download.svg'})
        }
      } else {
        for (let i = 0; i < this.elements.length; i++) {
          if (this.elements[i].id === this.selectedElements[0]) {
            let isAllowedToDownloadOriginal = !!this.elements[i]?.media?.links.find(l => l.rel === 'download')
            if (isAllowedToDownloadOriginal) {
              entries.push({id: 'original', label: this.$t('generic.download_original'), icon: '/icons/download.svg'})
            }
          }
        }
      }
      return entries
    },
    showDownloadButton() {
      let templateAttr = this.category.attributes?.find(attr => attr.name === 'CMS-Template')
      let componentType = this.$helper.resolveComponentTypeByCategory(this.category, templateAttr)
      let isDownloadableType = componentType === 'page'
          || componentType === 'audio'
          || componentType === 'video'
          || componentType === 'pdf'
      if (!isDownloadableType) {
        if (this.selectedElements.length !== 1) return false
        else {
          for (let i = 0; i < this.elements.length; i++) {
            if (this.elements[i].id === this.selectedElements[0]) {
              if (this.elements[i].categorytype !== 'media') {
                return false;
              }
            }
          }
          return true;
        }
      }
      return this.$helper.findAttributeValue(this.category, 'CMS-Configuration')?.includes('EnableDownload')
          && isDownloadableType
    },
    showUploadButton() {
      let templateAttr = this.category.attributes?.find(attr => attr.name === 'CMS-Template')
      let componentType = this.$helper.resolveComponentTypeByCategory(this.category, templateAttr)
      let z = this.$helper.findAttributeValue(this.category, 'CMS-Configuration')?.includes('EnableUpload')
          && (componentType === 'folder' || componentType === 'folderflickr')
          && !this.category.readonly
      if (this.selectedElements.length > 0) z = false
      this.$store.commit('category/showEditButtons', z)
      return z
    },
    showNewCategoryButton() {
      let templateAttr = this.category.attributes?.find(attr => attr.name === 'CMS-Template')
      let componentType = this.$helper.resolveComponentTypeByCategory(this.category, templateAttr)
      return this.$helper.findAttributeValue(this.category, 'CMS-Configuration')?.includes('EnableNewCategory')
          && (componentType === 'folder' || componentType === 'folderflickr')
          && !this.category.readonly && this.selectedElements.length === 0
    },
    showSortCategoryButton() {
      let templateAttr = this.category.attributes?.find(attr => attr.name === 'CMS-Template')
      let componentType = this.$helper.resolveComponentTypeByCategory(this.category, templateAttr)
      return this.$helper.findAttributeValue(this.category, 'CMS-Configuration')?.includes('EnableSortCategory')
          && componentType === 'folder'
          && !this.category.readonly
    },
    showEditCategoryButton() {
      return this.$helper.findAttributeValue(this.category, 'CMS-Configuration')?.includes('EnableEditCategory')
          && !this.category.readonly
    },
    showEditMenuCategoryButton() {
      if (this.selectedElements.length === 0) {
        return !this.category.readonly
      } else {
        return this.checkselectedElements
      }
    },
    showDeleteCategoryButton() {
      if (this.selectedElements.length === 0) {
        return this.$helper.findAttributeValue(this.category, 'CMS-Configuration')?.includes('EnableDeleteCategory')
            && !this.category.readonly
      } else {
        return this.checkselectedElements
      }
    },
    checkselectedElements() {
      for (let j = 0; j < this.selectedElements.length; j++) {
        for (let i = 0; i < this.elements.length; i++) {
          if (this.elements[i].id === this.selectedElements[j]) {
            if (this.elements[i].readonly) {
              return false;
            }
          }
        }
      }
      return true;
    }
  },
  data() {
    return {
      loading: false,
      loadingMessage: null,
      showDetailPage: false,
      showNewCategoryModal: false,
      showSortCategoryModal: false,
      showEditCategoryModal: false,
      showDeleteCategoryModal: false,
    }
  },
  created() {
    this.loadValueDomains()
    this.$store.commit('category/setSelectedElements', [])
    // if (this.category?.categorytype !== 'folder') {
    //   this.showDetailPage = true
    //   this.$store.commit('category/setSelectedElements', [this.category?.id])
    // }
  },
  methods: {
    enableUpload() {
      this.$refs.file.click()
    },
    async upload($event) {
      this.loading = true
      this.loadingMessage = this.$t('folders.uploading')
      for (let i = 0; i < $event.target.files.length; i++) {
        let file = $event.target.files[i]
        if (file) {
          let mediaResponse = await this.$api.createMediaCategory(this.category.id, {_category: file.name})
          if (mediaResponse) {
            let newCategoryId = await mediaResponse.text()
            await this.$api.uploadToMediaCategory(newCategoryId, file)
          }
        }
      }
      this.loading = false
      setTimeout(() => this.$router.go(), 500)
    },
    async useDropdown($event) {
      if ($event == 'original') $event = 'download'
      let url
      if (this.selectedElements.length === 0) {
        url = this.category?.media?.links?.find(l => l.rel === $event)?.href
      } else {
        for (let i = 0; i < this.elements.length; i++) {
          if (this.elements[i].id === this.selectedElements[0]) {
            url = this.elements[i]?.media?.links?.find(l => l.rel === $event)?.href + "&d=" + this.elements[i].categoryname
            break;
          }
        }
      }
      let element = document.createElement('a');
      element.setAttribute('href', url);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    async useEditDropdown($event) {
      switch ($event) {
        case 'sort':
          this.showSortCategoryModal = true
          break
        case 'newCategory':
          this.showNewCategoryModal = true
          break
        case 'deleteCategory':
          this.showDeleteCategoryModal = true
          break
        case 'editCategory':
          this.showEditCategoryModal = true
          break
        case 'copyCategory':
          this.$store.commit('category/setCopyElements', this.selectedElements)
          break
        case 'moveCategory':
          this.$store.commit('category/setMoveElements', this.selectedElements)
          break
        case 'pasteCategory':
          this.loading = true
          if (this.copyElements?.length > 0) {
            await this.$api.copyCategory(this.category.id, this.copyElements)
          }
          if (this.moveElements?.length > 0) {
            await this.$api.moveCategory(this.category.id, this.moveElements)
          }
          this.$store.commit('category/clearCopyOrMoveElements')
          this.loading = false
          setTimeout(() => this.$router.go(), 500)
          break
        case 'select':
          this.$store.commit('category/setSelectedElements', this.elements.map(element => element.id))
          break
        case 'deselect':
          this.$store.commit('category/setSelectedElements', [])
          break
      }
    },
    async deleteCategory() {
      this.loading = true
      this.loadingMessage = this.$t('folders.deleting')
      for (let i = 0; i < this.selectedElements.length; i++) {
        await this.$api.deleteCategory(this.selectedElements[i])
      }

      if (this.showDetailPage) {
        let pathArray = this.$route.path.split('/')
        if (pathArray && pathArray.length > 1) {
          pathArray.pop()
          let newRoute = pathArray.join('/')
          if (newRoute) {
            setTimeout(() => this.$router.push(newRoute), 200)
          }
        }
      } else {
        setTimeout(() => this.$router.go(), 200)
      }

      this.loading = false
      this.showDeleteCategoryModal = false
    },
    async loadValueDomains() {
      if (!this.valueDomains || this.valueDomains.length === 0) {
        let valueDomains = this.userAttributes
            .filter(attr => `${attr.isa}`.toLowerCase() === 'select_list')
            .filter((attr, index, self) => {
              let attrIndex = self.findIndex(sAttr => sAttr.name === attr.name)
              return index === attrIndex
            })
            .map(attr => attr.name)
        this.$store.commit('auth/setValueDomains', await this.$api.findValueDomains(valueDomains))
      }
    }
  },
  mixins: [
    ContentMixin,
  ],
  inject: [
    '$api',
    '$helper',
  ],
}
</script>

<style lang="scss" scoped>
.default-layout {
  @apply
  flex
  w-full
  h-full
  flex-col
  items-center
  overflow-y-auto;

  .component {
    @apply
    h-full
    w-full
    max-w-full;
  }

  .current-category {
    @apply
    flex
    mt-5
    pb-0
    w-full
    text-lg
    px-4
    md:px-6
    md:flex-row
    md:text-2xl
    justify-between;

    .title {
      @apply
      px-2
      bg-white
      bg-opacity-90;
    }
  }

  .component-content {
    @apply
    p-0
    m-auto
    w-full
    h-full
    overflow-x-hidden;

    &.component-content--tight {
      @apply
      md:py-4
      max-w-full;
    }

    &.component-content--without-breadcrumbs {
      @apply
      mt-0;
    }

    &.component-content--with-heading {
      max-height: calc(100% - 59px);
    }

    &.component-content--with-breadcrumbs {
      max-height: calc(100% - 100px);
    }
  }
}

.options {
  @apply
  w-[88px]
  h-[48px];

  button {
    @apply
    hover:bg-white
    w-[48px]
    h-[48px];
    border-radius: 50%;
    background: transparent;
    border: 0px;

    > div {
      width: 40px;
      height: 40px;
    }
  }

  svg {
    width: 40px;
    height: 40px;
  }

  ~ button {
    min-height: 48px;
    height: 48px;
    min-width: 48px;
    @media (max-width: 767px) {
      padding: 0;
    }
    &:before {
      content: '';
      display: inline-block;
/*      height: 40px;
      width: 40px;*/
      height: 24px;
      width: 24px;
      /*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='40' width='40'%3E%3Cpath d='M10.542 33.333q-3.625 0-6.25-2.604T1.667 24.5q0-3.25 2.021-5.75 2.02-2.5 5.27-3.083.875-3.959 3.979-6.459 3.105-2.5 7.105-2.5 4.791 0 8.041 3.396t3.25 8.188v.666q2.959.042 4.979 2.104 2.021 2.063 2.021 5.063 0 2.958-2.125 5.083t-5.083 2.125h-9.75q-1.125 0-1.937-.833-.813-.833-.813-1.958v-10l-3.208 3.166-1.959-2L20 15.167l6.542 6.541-1.959 2-3.208-3.166v10h9.75q1.833 0 3.125-1.292t1.292-3.125q0-1.833-1.292-3.125t-3.125-1.292h-2.583v-3.416q0-3.625-2.48-6.229-2.479-2.605-6.104-2.605t-6.146 2.605q-2.52 2.604-2.52 6.229H10.5q-2.542 0-4.292 1.77-1.75 1.771-1.75 4.355 0 2.541 1.792 4.333 1.792 1.792 4.292 1.792h5.291v2.791ZM20 21.375Z'/%3E%3C/svg%3E");*/
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' width='24'%3E%3Cpath d='M11 19h2v-4.175l1.6 1.6L16 15l-4-4-4 4 1.425 1.4L11 14.825Zm-5 3q-.825 0-1.412-.587Q4 20.825 4 20V4q0-.825.588-1.413Q5.175 2 6 2h8l6 6v12q0 .825-.587 1.413Q18.825 22 18 22Zm7-13V4H6v16h12V9ZM6 4v5-5 16V4Z'/%3E%3C/svg%3E") no-repeat;
    }

    span {
      display: none;
    }
  }
}

.controls {
  @apply
  flex
  gap-2
  md:px-0;

  .folder-controls {
    @apply
      /*w-full*/
    md:w-auto
    flex;
    .button {
      text-indent: -9999px;
      @screen md {
        text-indent: 0;
      }
    }

    span {
      @apply
      hidden
      sm:block;
      display: none;
    }
  }
}

.upload-loading-indicator {
  @apply
  px-4
  flex
  gap-2
  text-center
  flex-col
  z-50
  fixed
  top-0
  left-0
  w-full
  h-full
  bg-white
  items-center
  justify-center
  bg-opacity-90;
}
</style>